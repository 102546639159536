import Vue from "vue";
import VueRouter from "vue-router";

// 引入组件
// import home from "../component/home/home.vue";
// import mHome from "../component/home/m_home.vue";
// import cases from "../component/case/case.vue";
// import news from "../component/news/news.vue";
// import newslist from "../component/news/newslist.vue";
// import about from "../component/about/about.vue";

import rePhotoHome from "../pages/rephoto/home/home.vue";
import rePhotoMHome from "../pages/rephoto/home/m_home.vue";
import rePhotoCases from "../pages/rephoto/case/case.vue";
import rePhotoNews from "../pages/rephoto/news/news.vue";
import rePhotoNewslist from "../pages/rephoto/news/newslist.vue";
import rePhotoAbout from "../pages/rephoto/about/about.vue";

import rePhotoMHomeV2 from "../pages/rephoto_v2/rephoto.vue";

import idpIndex from "../pages/idphoto/index/index.vue";
import idpIndex_pw from "../pages/idphoto/index_pw/index_pw.vue";
import idpJiehun from "../pages/idphoto/jiehun/jiehun.vue";
import idpYinxiang from "../pages/idphoto/yinxiang/yinxiang.vue";
import idpZixun from "../pages/idphoto/zixun/zixun.vue";
import idpAbout from "../pages/idphoto/about/about.vue";

import idpMIndex from "../pages/idphoto/m_index/m_index.vue";
import idpMIndex_pw from "../pages/idphoto/m_index_pw/m_index_pw.vue";
import idpMJiehun from "../pages/idphoto/m_jiehun/m_jiehun.vue";
import mJiehunV2 from "../pages/idphoto_v2/jiehun/jiehun.vue";

import idpMYinxiang from "../pages/idphoto/m_yinxiang/m_yinxiang.vue";
import yinxiangV2 from "../pages/idphoto_v2/yinxiang/yinxiang.vue";

import idpMZixun from "../pages/idphoto/m_zixun/m_zixun.vue";
import idpMAbout from "../pages/idphoto/m_about/m_about.vue";


import idpArtical1 from "../pages/idphoto/artical/031716511148/031716511148.vue";
import idpArtical2 from "../pages/idphoto/artical/031717272818/031717272818.vue";
import idpArtical3 from "../pages/idphoto/artical/031717515628/031717515628.vue";
import idpArtical4 from "../pages/idphoto/artical/031718575701/031718575701.vue";
import idpArtical5 from "../pages/idphoto/artical/031719060900/031719060900.vue";
import idpArtical6 from "../pages/idphoto/artical/031809512218/031809512218.vue";
import idpArtical7 from "../pages/idphoto/artical/031809530398/031809530398.vue";
import idpArtical8 from "../pages/idphoto/artical/031809560240/031809560240.vue";

import studioIndex from "../pages/idphoto/studio_index/studio_index.vue";
import studioMIndex from "../pages/idphoto/m_studio_index/m_studio_index.vue";

import videoRestoreIndex from "../pages/video_restore/vr/vr_index.vue";
import videoRestoreMIndex from "../pages/video_restore/m_vr/vr_index.vue";

import magicLogoIndex from "../pages/magic_logo/pc_magic/pc_magic.vue";
import magicLogoMIndex from "../pages/magic_logo/m_magic/m_magic.vue";

import serviceSystem from "../pages/customer_service/customer_service.vue";

import zuimei from "../pages/zuimei/zuimei.vue";

import reVideo from "../pages/revideo/revideo.vue";
import photoBook from "../pages/photobook/photobook.vue";
import taner from "../pages/taner/taner.vue";
import pdfnote from "../pages/pdfnote/pdfnote.vue";


// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);

const routes = [
    {
        path: "/home",
        name: 'home',
        component: rePhotoHome,
        meta: {
            title: "照片修复",
            content: {
                keywords: '照片修复',
                description: '照片修复',
            },
        }
    },
    {
        path: "/home.html",
        name: 'home',
        component: rePhotoHome,
        meta: {
            title: "照片修复",
            content: {
                keywords: '照片修复',
                description: '照片修复',
            },
        }
    },
    {
        path: "/m_home",
        name: 'm_home',
        component: rePhotoMHomeV2,
        meta: {
            title: "照片修复",
            content: {
                keywords: '照片修复',
                description: '照片修复',
            },
        }
    },

    {
        path: "/m_home.html",
        name: 'm_home',
        component: rePhotoMHomeV2
    },
    {
        path: "/cases",
        name: 'cases',
        component: rePhotoCases,
        meta: {
            title: "照片修复",
            content: {
                keywords: '照片修复',
                description: '照片修复',
            },
        }
    },
    {
        path: "/news",
        name: 'news',
        component: rePhotoNews,
        meta: {
            title: "照片修复",
            content: {
                keywords: '照片修复',
                description: '照片修复',
            },
        }
    },
    {
        path: "/newslist",
        name: 'newslist',
        component: rePhotoNewslist,
        meta: {
            title: "照片修复",
            content: {
                keywords: '照片修复',
                description: '照片修复',
            },
        }
    },
    {
        path: "/about",
        name: 'about',
        component: rePhotoAbout,
        meta: {
            title: "照片修复",
            content: {
                keywords: '照片修复',
                description: '照片修复',
            },
        }
    },
    //idphoto.online
    {
        path: "/index",
        name: 'index',
        component: idpIndex,
        meta: {
            title: "印象证件照",
            content: {
                keywords: '印象证件照',
                description: '印象证件照',
            },
        }
    },
    //techpuwu.com
    // {
    //     path: "/index",
    //     name: 'index',
    //     component: idpIndex_pw,
    //     meta: {
    //         title: "印象证件照",
    //         content: {
    //             keywords: '印象证件照',
    //             description: '印象证件照',
    //         },
    //     }
    // },
    //techpuwu
    // {
    //     path: "/m_index",
    //     name: 'm_index',
    //     component: idpMIndex_pw,
    //     meta: {
    //         title: "印象证件照",
    //         content: {
    //             keywords: '印象证件照',
    //             description: '印象证件照',
    //         },
    //     }
    // },
    {
        path: "/jiehun",
        name: 'jiehun',
        component: idpJiehun,
        meta: {
            title: "结婚证件照",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },
    {
        path: "/yinxiang",
        name: 'yinxiang',
        component: idpYinxiang,
        meta: {
            title: "印象证件照",
            content: {
                keywords: '印象证件照',
                description: '印象证件照',
            },
        }
    },
    {
        path: "/zixun",
        name: 'zixun',
        component: idpZixun,
        meta: {
            title: "证件照",
            content: {
                keywords: '印象证件照',
                description: '印象证件照',
            },
        }
    },
    {
        path: "/artical/031716511148",
        name: 'artical/031716511148',
        component: idpArtical1,
        meta: {
            title: "文章咨询",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },
    {
        path: "/artical/031717272818",
        name: 'artical/031717272818',
        component: idpArtical2,
        meta: {
            title: "文章咨询",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    }, {
        path: "/artical/031717515628",
        name: 'artical/031717515628',
        component: idpArtical3,
        meta: {
            title: "文章咨询",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    }, {
        path: "/artical/031718575701",
        name: 'artical/031718575701',
        component: idpArtical4,
        meta: {
            title: "文章咨询",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },
    {
        path: "/artical/031719060900",
        name: 'artical/031719060900',
        component: idpArtical5,
        meta: {
            title: "文章咨询",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },

    {
        path: "/artical/031809512218",
        name: 'artical/031809512218',
        component: idpArtical6,
        meta: {
            title: "文章咨询",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },
    {
        path: "/artical/031809530398",
        name: 'artical/031809530398',
        component: idpArtical7,
        meta: {
            title: "文章咨询",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },
    {
        path: "/artical/031809560240",
        name: 'artical/031809560240',
        component: idpArtical8,
        meta: {
            title: "文章咨询",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },


    {
        path: "/idp_about",
        name: 'idp_about',
        component: idpAbout,
        meta: {
            title: "证件照",
            content: {
                keywords: '印象证件照',
                description: '印象证件照',
            },
        }
    },
    {
        path: "/m_index",
        name: 'm_index',
        component: idpMIndex,
        meta: {
            title: "结婚证件照",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },
    {
        path: "/m_jiehun",
        name: 'm_jiehun',
        component: mJiehunV2,
        meta: {
            title: "结婚证件照",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },
    {
        path: "/m_jiehun_v2",
        name: 'm_jiehun_v2',
        component: mJiehunV2,
        meta: {
            title: "结婚证件照",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },
    {
        path: "/m_yinxiang",
        name: 'm_yinxiang',
        component: yinxiangV2,
        meta: {
            title: "印象证件照",
            content: {
                keywords: '印象证件照',
                description: '印象证件照',
            },
        }
    },
    {
        path: "/m_zixun",
        name: 'm_zixun',
        component: idpMZixun,
        meta: {
            title: "证件照",
            content: {
                keywords: '印象证件照',
                description: '印象证件照',
            },
        }
    },
    {
        path: "/m_about",
        name: 'm_about',
        component: idpMAbout,
        meta: {
            title: "证件照",
            content: {
                keywords: '印象证件照',
                description: '印象证件照',
            },
        }
    },
    {
        path: "/studio_index",
        name: 'studio_index',
        component: studioIndex,
        meta: {
            title: "证件照相馆"
        }
    },
    {
        path: "/m_video_restore",
        name: 'm_video_restore',
        component: videoRestoreMIndex,
        meta: {
            title: "视频照片恢复"
        }
    },
    {
        path: "/m_video_restore.html",
        name: 'm_video_restore',
        component: videoRestoreMIndex,
        meta: {
            title: "视频照片恢复"
        }
    },
    {
        path: "/video_restore",
        name: 'video_restore',
        component: videoRestoreIndex,
        meta: {
            title: "视频照片恢复"
        }
    },
    {
        path: "/video_restore.html",
        name: 'video_restore',
        component: videoRestoreIndex,
        meta: {
            title: "视频照片恢复"
        }
    },
    {
        path: "/m_magic_logo",
        name: "m_magic_logo",
        component: magicLogoMIndex,
        meta: {
            title: "水印魔法师"
        }
    },
    {
        path: "/m_magic_logo.html",
        name: "m_magic_logo",
        component: magicLogoMIndex,
        meta: {
            title: "水印魔法师"
        }
    },
    {
        path: "/magic_logo",
        name: "magic_logo",
        component: magicLogoIndex,
        meta: {
            title: "水印魔法师"
        }
    },
    {
        path: "/magic_logo.html",
        name: "magic_logo",
        component: magicLogoIndex,
        meta: {
            title: "水印魔法师"
        }
    },
    {
        path: "/m_studio_index",
        name: 'm_studio_index',
        component: studioMIndex,
        meta: {
            title: "证件照照相馆"
        }
    },
    {
        path: "/m_studio_index.html",
        name: 'm_studio_index',
        component: studioMIndex,
        meta: {
            title: "证件照照相馆"
        }
    },
    {
        path: "/index.html",
        name: 'index',
        component: idpIndex,
        meta: {
            title: "结婚证件照",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },
    {
        path: "/jiehun.html",
        name: 'jiehun',
        component: idpJiehun,
        meta: {
            title: "结婚证件照",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },
    {
        path: "/yinxiang.html",
        name: 'yinxiang',
        component: idpYinxiang,
        meta: {
            title: "印象证件照",
            content: {
                keywords: '印象证件照',
                description: '印象证件照',
            },
        }
    },
    {
        path: "/m_index.html",
        name: 'm_index',
        component: idpMIndex,
        meta: {
            title: "结婚证件照",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },
    {
        path: "/m_jiehun.html",
        name: 'm_jiehun',
        component: mJiehunV2,
        meta: {
            title: "结婚证件照",
            content: {
                keywords: '结婚证件照',
                description: '结婚证件照',
            },
        }
    },
    {
        path: "/m_yinxiang.html",
        name: 'm_yinxiang',
        component: yinxiangV2,
        meta: {
            title: "印象证件照",
            content: {
                keywords: '印象证件照',
                description: '印象证件照',
            },
        }
    },
    {
        path: "/studio_index.html",
        name: 'studio_index',
        component: studioIndex,
        meta: {
            title: "证件照照相馆"
        }
    },
    {
        path: "/chat",
        name: 'chat',
        component: serviceSystem,
        meta: {
            title: "百鸟客服系统"
        }
    },

    // 印象版本2
    {
        path: "/yx",
        name: 'yx',
        component: yinxiangV2,
        meta: {
            title: "印象证件照",
            content: {
                keywords: '印象证件照',
                description: '印象证件照',
            },
        }
    },
    // 照片修复版本2
    {
        path: "/rephoto-v2",
        name: 'rephoto-v2',
        component: rePhotoMHomeV2,
        meta: {
            title: "照片修复",
            content: {
                keywords: '照片修复',
                description: '照片修复',
            },
        }
    },
    // 最美证件照专业版
    {
        path: "/m_zuimei",
        name: 'm_zuimei',
        component: zuimei,
        meta: {
            title: "最美证件照专业版",
            content: {
                keywords: '最美证件照专业版',
                description: '最美证件照专业版',
            },
        }
    },
    {
        path: "/zuimei",
        name: 'zuimei',
        component: zuimei,
        meta: {
            title: "最美证件照专业版",
            content: {
                keywords: '最美证件照专业版',
                description: '最美证件照专业版',
            },
        }
    },
    {
        path: "/revideo",
        name: 'revideo',
        component: reVideo,
        meta: {
            title: "视频照片恢复清晰",
            content: {
                keywords: '视频照片恢复清晰',
                description: '视频照片恢复清晰',
            },
        }
    },
    {
        path: "/photobook",
        name: 'photobook',
        component: photoBook,
        meta: {
            title: "印记·照片书",
            content: {
                keywords: '照片书',
                description: '照片书',
            },
        }
    },
    {
        path: "/pdfnote",
        name: 'pdfnote',
        component: pdfnote,
        meta: {
            title: "PDF·笔记",
            content: {
                keywords: 'PDF笔记',
                description: 'PDF笔记',
            },
        }
    },
    {
        path: "/taner",
        name: 'taner',
        component: taner,
        meta: {
            title: "摆地摊",
            content: {
                keywords: '摆地摊',
                description: '摆地摊',
            },
        }
    },


    // 重定向
    {
        path: '/',
        redirect: '/index'
    }
]

var router = new VueRouter({
    mode: 'history',
    routes
})
export default router;